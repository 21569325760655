import {
    SET_IS_MOBILE_MENU_OPEN,
    SET_IS_MOBILE_SITE,
    SET_JOIN_BUTTON,
} from "../actions/types";

const initialState = {
    isMobileMenuOpen: false,
    mobile_breakpoint: 1200,
    isMobileSite: false,
    showJoinButton: false,
    tags: [],
    ok: false,
    message: "",
    errors: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_JOIN_BUTTON:
            return {
                ...state,
                showJoinButton: action.payload,
            };
        case SET_IS_MOBILE_MENU_OPEN:
            return {
                ...state,
                isMobileMenuOpen: action.payload,
            };
        case SET_IS_MOBILE_SITE:
            return {
                ...state,
                isMobileSite: action.payload,
            };
        default:
            return state;
    }
}

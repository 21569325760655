import fbIcon from '../icons/social/Facebook.svg';
import fbIconAlt from '../icons/social/fb-hover.svg';
import tWIcon from '../icons/social/Twitter.svg';
import tWIconAlt from '../icons/social/twitter-hover.svg';
import lNIcon from '../icons/social/Linkedin.svg';
import lNIconAlt from '../icons/social/linkedin-hover.svg';
import insIcon from '../icons/social/Instagram.svg';
import insIconAlt from '../icons/social/insta-hover.svg';

export const socials = [
    {
        name: 'Facebook',
        link: 'https://www.facebook.com/MyMoneyJar',
        icon: fbIcon,
        iconAlt: fbIconAlt,
    },
    {
        name: 'Twitter',
        link: 'https://twitter.com/moneyjar_',
        icon: tWIcon,
        iconAlt: tWIconAlt,
    },
    {
        name: 'LinkedIn',
        link: 'https://ie.linkedin.com/company/my-money-jar',
        icon: lNIcon,
        iconAlt: lNIconAlt,
    },
    {
        name: 'Instagram',
        link: 'https://instagram.com/moneyjar_',
        icon: insIcon,
        iconAlt: insIconAlt
    }
];

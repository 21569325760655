import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { DgPicture } from "dg-utils";

// Assets
import applePay from "../../../../assets/images/home-apple-pay.png";
import applePayLogo from "../../../../assets/images/applePayLogo.svg";

//Component
import LeftRight from "../../../blocks/leftRight/leftRight";

// Actions
import { setIsMobileSite } from "../../../../actions/utility.action";
import { navigateTo } from "../../../../actions/utility.action";
import intl from 'react-intl-universal';

class SpendApplePay extends Component {
    render() {
        return (
            <LeftRight
	            config="section-apple-pay"
                left={
                    <div className="wrapper">
	                    <img
		                    src={applePayLogo}
		                    alt="Apple Pay"
		                    loading="lazy"
		                    className="logo"
	                    />
                        <h2>
	                        { intl.getHTML('page.Spend.ApplePay.h') }
                        </h2>
                        <p className="semi-bold">
	                        { intl.getHTML('page.Spend.ApplePay.p') }
                        </p>
                        <button className="btn-primary">
                            <NavLink
                                onClick={() => navigateTo()}
                                to="/apple-pay"
                            >
	                            { intl.getHTML('page.Spend.ApplePay.cta') }
                            </NavLink>
                        </button>
                    </div>
                }
                right={
                    <div
                        className="wrapper image-container"
                    >
                        <DgPicture className="phone-img" >
                            <img
                                src={applePay}
                                alt=""
                                loading="lazy"
                            />
                        </DgPicture>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isMobileSite: state.utility.isMobileSite,
});

export default connect(mapStateToProps, {
    setIsMobileSite,
})(SpendApplePay);

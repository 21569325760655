import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { DgCookies } from "dg-cookies";
import intl from 'react-intl-universal';

// Styles
import "../../styles/components/navbar/navbar.scss";

// Actions
import {
    setIsMobileMenuOpen,
    setIsMobileSite,
    setShowJoinButton,
    navigateTo,
} from "../../actions/utility.action";

// Assets
import logo from "../../assets/logos/MJ-logo wide-wht.svg";
import logoIcon from "../../assets/logos/logo-icon.png";
import { scripts } from "../../assets/files/cookies";

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMobileAppMenu: false,
        };

        this.openClose = this.openClose.bind(this);
        this.navigateTo = this.navigateTo.bind(this);
        this.checkIsMobileDevice = this.checkIsMobileDevice.bind(this);
        this.listenToScroll = this.listenToScroll.bind(this);
    }

    componentDidMount() {
        this.checkIsMobileDevice();
        window.addEventListener("resize", this.checkIsMobileDevice);
        window.addEventListener("scroll", this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkIsMobileDevice);
        window.removeEventListener("scroll", this.listenToScroll);
    }

    checkIsMobileDevice() {
        let isMobileDevice =
            window.innerWidth <= this.props.mobile_breakpoint + 1;
        this.props.setIsMobileSite(isMobileDevice);
    }

    listenToScroll() {
        let height = window.innerHeight;
        let position = window.pageYOffset;

        if (position >= height) {
            this.props.setShowJoinButton(true);
        } else {
            this.props.setShowJoinButton(false);
        }
    }

    openClose() {
        this.props.setIsMobileMenuOpen(!this.props.utility.isMobileMenuOpen);
    }

    navigateTo() {
        window.scrollTo(0, 0);
        this.props.setIsMobileMenuOpen(false);
    }

    render() {
        return (
            <div className="navbar-container">
                <div className={`navbar ${this.props.utility?.isMobileMenuOpen ? "open" : ""}`}>
                    <div className="navbar-main">
                        <div className="navbar-logo-container">
                            <div className="logo">
                                <NavLink
                                    to="/"
                                    className="nav-link"
                                    onClick={() => this.navigateTo()}
                                    exact
                                >
	                                <img src={this.props.utility.isMobileMenuOpen ? logoIcon : logo} alt={process.env.REACT_APP_PROJECT_NAME} />
                                </NavLink>
                            </div>
                            <div className="join-button">
                                <Link to="/download">
                                    <button onClick={() => this.navigateTo()} className="btn-primary">
                                        { intl.get('component.Navbar.download') }
                                    </button>
                                </Link>
                            </div>
                            <div className="close-container">
                                <button
                                    type="button"
                                    className="close-button"
                                    onClick={() => this.openClose()}
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M14.6672 0.344125C14.4547 0.131096 14.1661 0.0113766 13.8652 0.0113766C13.5643 0.0113766 13.2757 0.131096 13.0631 0.344125L7.5 5.8959L1.93686 0.332749C1.72431 0.119719 1.43574 0 1.13481 0C0.833882 0 0.545315 0.119719 0.332765 0.332749C-0.110922 0.776435 -0.110922 1.49316 0.332765 1.93685L5.8959 7.49999L0.332765 13.0631C-0.110922 13.5068 -0.110922 14.2235 0.332765 14.6672C0.776451 15.1109 1.49317 15.1109 1.93686 14.6672L7.5 9.10409L13.0631 14.6672C13.5068 15.1109 14.2235 15.1109 14.6672 14.6672C15.1109 14.2235 15.1109 13.5068 14.6672 13.0631L9.1041 7.49999L14.6672 1.93685C15.0995 1.50454 15.0995 0.776435 14.6672 0.344125Z"
                                            fill="#0F3C32"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <ul className="navbar-list">
                            <li className="nav-item">
                                <NavLink
                                    to="/setup"
                                    className="nav-link"
                                    onClick={() => this.navigateTo()}
                                    exact
                                >
                                    <span className="link-text">{ intl.get('component.Navbar.setup') }</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to="/plan"
                                    className="nav-link"
                                    onClick={() => this.navigateTo()}
                                    exact
                                >
                                    <span className="link-text">{ intl.get('component.Navbar.plan') }</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to="/spend"
                                    className="nav-link"
                                    onClick={() => this.navigateTo()}
                                    exact
                                >
                                    <span className="link-text">{ intl.get('component.Navbar.spend') }</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to="/about"
                                    className="nav-link"
                                    onClick={() => this.navigateTo()}
                                    exact
                                >
                                    <span className="link-text">{ intl.get('component.Navbar.about') }</span>
                                </NavLink>
                            </li>

                            <li
                                className={`nav-item dropdown ${
                                    this.state.showMobileAppMenu ? "open" : ""
                                }`}
                            >
                                <span
                                    className={`${
                                        this.props.isMobileSite
                                            ? "nav-link desktop-only"
                                            : "nav-link"
                                    }`}
                                >
                                    <span className="link-text">{ intl.get('component.Navbar.help') }</span>
                                </span>
                                <div className="dropdown">
                                    <ul className="dropdown-list">
                                        <li className="dropdown-item">
                                            <NavLink
                                                to="/contact"
                                                className="nav-link"
                                                onClick={() =>
                                                    this.navigateTo()
                                                }
                                                exact
                                            >
                                                <span className="link-text">
                                                    { intl.get('component.Navbar.contact') }
                                                </span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li
                                className={`nav-item dropdown ${
                                    this.state.showMobileAppMenu ? "open" : ""
                                }`}
                            >
                                <div className="desktop-only">
                                    <Link to="/download">
                                        <button
                                            onClick={() => this.navigateTo()}
                                            className="btn-primary"
                                        >
	                                        { intl.get('component.Navbar.download') }
                                        </button>
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </div>

                    {/* Cookies */}
	                <DgCookies
		                scripts={scripts}
		                cookies_page_uri={`/privacy-policy`}
	                />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    utility: state.utility,
    mobile_breakpoint: state.utility.mobile_breakpoint,
    isMobileSite: state.utility.isMobileSite,
});

export default connect(mapStateToProps, {
    setIsMobileSite,
    setIsMobileMenuOpen,
    setShowJoinButton,
    navigateTo,
})(Navbar);

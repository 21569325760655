import React, { Component } from "react";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';

// Assets
import intro from "../../../../assets/images/home-hero-phones-new.png";

//Component
import LeftRight from "../../../blocks/leftRight/leftRight";

class PlanFree extends Component {
    render() {
        return (
            <LeftRight
                left={
                    <div className="wrapper">
                        <DgPicture className="extra-padding">
                            <img
                                src={intro}
                                alt=""
                                loading="lazy"
                            />
                        </DgPicture>
                    </div>
                }
                right={
                    <div className="wrapper">
                        <h2>{ intl.getHTML('page.Plan.Free.h') }</h2>
                        <p>
	                        { intl.getHTML('page.Plan.Free.p') }
                        </p>
                    </div>
                }
                config={`green right`}
            />
        );
    }
}

export default PlanFree;

import React, { Component } from "react";
import { DgPicture } from "dg-utils";
import { Link } from "react-router-dom";

// Assets
import spendBg from "../../../../assets/images/spend-nohidden-1-alt.jpg";
import spendBgMobile from "../../../../assets/images/spend-nohidden-1-mobile.jpg";

//Component
import LeftRightMiddle from "../../../blocks/leftRight/leftRightMiddle";

// Actions
import { navigateTo } from "../../../../actions/utility.action";
import intl from 'react-intl-universal';

class HomePayBoost extends Component {
    render() {
        return (
            <LeftRightMiddle
                hideOverlay={true}
                customLeftRight={"LeftRightMiddle-container reversed"}
                left={<div />}
                middle={<div />}
                right={
                    <div className="wrapper">
                        <h2>{ intl.getHTML('page.Spend.No.h') }</h2>
                        <p>
	                        { intl.getHTML('page.Spend.No.p1') }
	                        {" "}
                            <Link onClick={() => navigateTo()}  to={`/terms-and-conditions`} className="embedded-link">
	                            { intl.getHTML('page.Spend.No.link') }
                            </Link>
	                        {" "}
	                        { intl.getHTML('page.Spend.No.p2') }
                        </p>
                        <p>{ intl.getHTML('page.Spend.No.p3') }</p>
                    </div>
                }
                bgImage={
                    <DgPicture className="image-container">
                        <img
                            src={spendBg}
                            alt=""
                            loading="lazy"
                        />
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container">
                        <img
                            src={spendBgMobile}
                            alt=""
                            loading="lazy"
                        />
                    </DgPicture>
                }
                config={`left-background`}
            />
        );
    }
}

export default HomePayBoost;

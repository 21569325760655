import React, { Component } from "react";
import Navbar from "../components/navbar/navbar";
import NavbarMobile from "../components/navbar/navbarMobile";
import Header from "../components/blocks/contentContainers/contact/contactHeader.component";
import Footer from "../components/footer";
import Offices from '../components/offices';

class Contact extends Component {
    render() {
        return (
            <div className="container" style={{ justifyContent: "space-evenly" }}>
              <Navbar/>
              <NavbarMobile/>
              <Header/>
              <Offices/>
              <Footer/>
            </div>
        );
    }
}

export default Contact;

import React, { Component } from "react";
import DeviceDetector from "device-detector-js";

// Components
import Navbar from "../components/navbar/navbar";
import NavbarMobile from "../components/navbar/navbarMobile";
import LandingComponent from "../components/landing/landing";

class Download extends Component {
    componentDidMount() {
        const deviceDetector = new DeviceDetector();
        const userAgent = navigator.userAgent;
        const device = deviceDetector.parse(userAgent);
        const deviceOS = device.os.name;

        console.log(deviceOS);
    }

    render() {
        return (
            <div
                className="container"
                style={{ justifyContent: "space-evenly" }}
            >
                <Navbar />
                <NavbarMobile />
                <LandingComponent />
            </div>
        );
    }
}

export default Download;

import React, { Component } from "react";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';

// Assets
import reliableBg from "../../../../assets/images/aboutus-reliable-1.jpg";
import reliableBgMobile from "../../../../assets/images/reliable.jpg";

//Component
import LeftRightMiddle from "../../leftRight/leftRightMiddle";

class AboutReliable extends Component {
    render() {
        return (
            <LeftRightMiddle
                customLeftRight={"LeftRightMiddle-container reversed"}
                left={<div />}
                middle={<div />}
                right={
                    <div className="wrapper">
                        <h2>{ intl.getHTML('page.About.Reliable.h') }</h2>
                        <p>
	                        { intl.getHTML('page.About.Reliable.p') }
                        </p>
                    </div>
                }
                bgImage={
                    <DgPicture className="image-container">
                        <img src={reliableBg} alt="" loading="lazy"/>
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container contain">
                        <img src={reliableBgMobile} alt="" loading="lazy"/>
                    </DgPicture>
                }
                config={`left-background`}
            />
        );
    }
}

export default AboutReliable;

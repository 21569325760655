import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';

import "../../../../styles/components/home/mobilePaymentsLogos.scss";

// Assets
import sideImg from "../../../../assets/images/home-mobilePayments.jpg";
import logos from "../../../../assets/images/home-mobilePaymentsLogos.png";

//Component
import LeftRightMiddle from "../../../blocks/leftRight/leftRightMiddle";

// Actions
import { setIsMobileSite } from "../../../../actions/utility.action";
import { navigateTo } from "../../../../actions/utility.action";

class HomeMobilePayments extends Component {
    render() {
        return (
            <LeftRightMiddle
                left={
                    <div className="wrapper">
	                    <img src={logos} alt="" loading="lazy" className={'logos'} />
                        <h2>
	                        { intl.get('page.Home.MobilePayments.h') }
                        </h2>
                        <p className="semi-bold">
	                        { intl.get('page.Home.MobilePayments.description') }
                        </p>
                        {/*<button className="btn-primary">*/}
                        {/*    <NavLink*/}
                        {/*        onClick={() => navigateTo()}*/}
                        {/*        to="/spend#google-pay"*/}
                        {/*    >*/}
	                      {/*      { intl.get('page.Home.MobilePayments.cta') }*/}
                        {/*    </NavLink>*/}
                        {/*</button>*/}
                    </div>
                }
                right={<div></div>}
                bgImage={
	                <DgPicture className="image-container">
		                <img src={sideImg} alt="" loading="lazy" />
	                </DgPicture>
                }
                bgImageMobile={
	                <DgPicture className="image-container">
		                <img src={sideImg} alt="" loading="lazy" />
	                </DgPicture>
                }
                config={`right-background mobile-payments-section`}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isMobileSite: state.utility.isMobileSite,
});

export default connect(mapStateToProps, {
    setIsMobileSite,
})(HomeMobilePayments);

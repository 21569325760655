import React, { Component } from "react";
import { DgPicture } from "dg-utils";

// Assets
import intro from "../../../../assets/images/numberous-jars-1-new.png";

//Component
import LeftRight from "../../../blocks/leftRight/leftRight";
import intl from 'react-intl-universal';
import IntlLinked from '../../../intlLinked';

class SpendFree extends Component {
    render() {
        return (
            <LeftRight
                left={
                    <div className="wrapper">
                        <DgPicture>
                            <img
                                src={intro}
                                alt=""
                                loading="lazy"
                            />
                        </DgPicture>
                    </div>
                }
                right={
                    <div className="wrapper">
                        <h2>{ intl.getHTML('page.Spend.Free.h') }</h2>
                        <p>
	                        { intl.getHTML('page.Spend.Free.p1') }
                        </p>
                        <p>
                          <IntlLinked path={'page.Spend.Free.p2'}></IntlLinked>
                        </p>
                    </div>
                }
                config={`green right`}
            />
        );
    }
}

export default SpendFree;

import React, { Component } from "react";
import "../styles/pages/policy.scss";
import Navbar from "../components/navbar/navbar";
import NavbarMobile from "../components/navbar/navbarMobile";
import Footer from "../components/footer";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ContentfulService from '../services/contentful.service';

class Policy extends Component {

	state = {
		title: '',
		content: ''
	}

	constructor(props) {
		super(props);
		this.fetchContent();
	}

	fetchContent = () => {
		const params = new URL(document.location.toString()).searchParams;
		ContentfulService.getInfoPage('privacyMay', params.get('market'), params.get('lang'))
				.then(entry => this.setState({
					title: entry.fields.title ,
					content: documentToReactComponents(entry.fields.body)
				}))
	}

	render() {
		return (
				<div
						className="container"
						style={{ justifyContent: "space-evenly" }}
				>
					<Navbar />
					<NavbarMobile />
					<article className="static-content">
						<h1>{this.state.title}</h1>
						<section>{this.state.content}</section>
					</article>
					<Footer />
				</div>
		);
	}
}

export default Policy;

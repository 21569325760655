import React, { Component } from "react";
import intl from 'react-intl-universal';
import flagEU from '../assets/images/flags/eu.svg';
import flagIE from "../assets/images/flags/ie.svg";
import flagES from "../assets/images/flags/es.svg";
import contactBgIE from "../assets/images/contact-bg-ie.jpg";
import contactBgES from "../assets/images/contact-bg-es.jpg";
import "../styles/components/offices.scss";

class Offices extends Component {
  render() {
    return (
        <section className="clamp offices">
          <article style={{ backgroundImage: `url(${contactBgIE})` }}>
            <h2>
              <img src={flagEU} alt="" loading="lazy"/>
              {intl.getHTML('component.Offices.h')}
            </h2>

            <div>
              <h2>
                <img src={flagIE} alt="" loading="lazy"/>
                {intl.getHTML('market.ie')}
              </h2>
              <address>
                {intl.getHTML('component.Offices.ie.address')}
              </address>
              <p>
                {intl.getHTML('component.Offices.ie.phoneH')}:
                <a href={'tel:' + intl.get('component.Offices.ie.phone')}>{intl.getHTML('component.Offices.ie.phone')}</a>
              </p>
              <p>
                {intl.getHTML('component.Offices.ie.emailH')}:
                <a href={'mailto:' + intl.get('component.Offices.ie.email')}>{intl.getHTML('component.Offices.ie.email')}</a>
              </p>
            </div>
          </article>

          <article style={{ backgroundImage: `url(${contactBgES})` }}>
            <div>
              <h2>
                <img src={flagES} alt="" loading="lazy"/>
                {intl.getHTML('market.es')}
              </h2>
              <address>
                {intl.getHTML('component.Offices.es.address')}
              </address>
              <p>
                {intl.getHTML('component.Offices.es.phoneH')}:
                <a href={'tel:' + intl.get('component.Offices.es.phone')}>{intl.getHTML('component.Offices.es.phone')}</a>
              </p>
              <p>
                {intl.getHTML('component.Offices.ie.emailH')}:
                <a href={'mailto:' + intl.get('component.Offices.es.email')}>{intl.getHTML('component.Offices.es.email')}</a>
              </p>
            </div>
          </article>
        </section>
    );
  }
}

export default Offices;

import React, { Component } from "react";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';

// Assets
import transparencyBg from "../../../../assets/images/aboutus-transpareny-1.jpg";
import transparencyBgMobile from "../../../../assets/images/aboutus-transpareny-1.jpg";

//Component
import LeftRightMiddle from "../../leftRight/leftRightMiddle";

class SetupTransparency extends Component {
    render() {
        return (
            <LeftRightMiddle
                hideOverlay={true}
                customLeftRight={"LeftRightMiddle-container reversed"}
                left={<div />}
                middle={<div />}
                right={
                    <div className="wrapper">
                        <h2>{ intl.getHTML('page.About.Transparency.h') }</h2>
                        <p>
	                        { intl.getHTML('page.About.Transparency.p') }
                        </p>
                    </div>
                }
                bgImage={
                    <DgPicture className="image-container">
                        <img
                            src={transparencyBg}
                            alt=""
                            loading="lazy"
                        />
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container">
                        <img
                            src={transparencyBgMobile}
                            alt=""
                            loading="lazy"
                        />
                    </DgPicture>
                }
                config={`left-background`}
            />
        );
    }
}

export default SetupTransparency;

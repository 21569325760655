import React, { Component } from "react";
import "../styles/pages/policy.scss";
import Navbar from "../components/navbar/navbar";
import NavbarMobile from "../components/navbar/navbarMobile";
import Footer from "../components/footer";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ContentfulService from '../services/contentful.service';
import { INLINES } from '@contentful/rich-text-types';

class Terms extends Component {

	state = {
		title: '',
		content: ''
	}

	constructor(props) {
		super(props);
		this.fetchContent();
	}

	fetchContent = () => {
		const url = new URL(document.location.toString());
		const params = url.searchParams;
		ContentfulService.getInfoPage('termsMay', params.get('market'), params.get('lang'))
				.then(entry => {
					this.setState({
						title: entry.fields.title,
						content: documentToReactComponents(entry.fields.body, {
							renderNode: {
								[INLINES.HYPERLINK]: (node, next) => (
										<a href={node.data.uri}
										   name={node.data.uri.startsWith('#') ? node.data.uri.slice(1) : null}
										   target={node.data.uri.startsWith('http') ? '_blank' : null}
										>{next}</a>
								)
							},
							preserveWhitespace: true,
						})
					})

					if (window.location.hash) {
						const anchor = document.querySelector('[name='+ window.location.hash.slice(1) +']')
						const navbarHeight = document.querySelector('.navbar').clientHeight;
						setTimeout(() => window.scrollTo({ top: anchor.offsetTop - navbarHeight, behavior: 'smooth' }), 500);
					}
				})
	}

	render() {
		return (
				<div
						className="container"
						style={{ justifyContent: "space-evenly" }}
				>
					<Navbar />
					<NavbarMobile />
					<article className="static-content">
						<h1>{this.state.title}</h1>
						<section>{this.state.content}</section>
					</article>
					<Footer />
				</div>
		);
	}
}

export default Terms;

import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { setIsMobileSite } from "../../../actions/utility.action";

// Style
import "../../../styles/components/blocks/leftRightMiddle.scss";

class LeftRightMiddle extends Component {
    render() {
        return (
            <div
                className={`clamp ${
                    this.props.customLeftRight
                        ? this.props.customLeftRight
                        : "LeftRightMiddle-container"
                }`}
            >
                <div
                    className={`background-container ${this.props.config ? this.props.config : ""}`}
                >
                    <div className={`inner-wrapper`} style={this.props.style}>
                        {this.props.left ? (
                            <div className="left-container">
                                <div
                                    style={this.props.leftStyle ? this.props.leftStyle : {}}
                                    className="left"
                                    data-aos="fade-right"
                                >
                                    {this.props.left}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        {!this.props.isMobileSite ? (
                            this.props.middle ? (
                                <div className="middle-container">
                                    <div className="middle" data-aos="fade-up">
                                        {this.props.middle}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )
                        ) : (
                            ""
                        )}
                        {this.props.right ? (
                            <div className="right-container">
                                <div
                                    style={this.props.rightStyle ? this.props.rightStyle : {}}
                                    className="right"
                                    data-aos="fade-left"
                                >
                                    {this.props.right}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    {this.props.hideOverlay ? `` : (<div className={`overlay`} />)}
                    <div className={`bg-image`}>
                        {!this.props.isMobileSite
                            ? this.props.bgImage
                                ? this.props.bgImage
                                : ""
                            : this.props.bgImageMobile
                            ? this.props.bgImageMobile
                            : ""}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isMobileSite: state.utility.isMobileSite,
});

export default connect(mapStateToProps, {
    setIsMobileSite,
})(LeftRightMiddle);

import React, { Component } from "react";
import intl from 'react-intl-universal';
import history from '../history';

class IntlLinked extends Component {

  path: string;

  constructor(props) {
    super();
    this.path = props.path;
  }

  onClick(e) {
    if (e.target.tagName === 'A') {
      e.preventDefault();
      const href = e.target.getAttribute('href');
      if (href.startsWith('/')) {
        window.scrollTo(0, 0);
        history.push(href);
      } else {
        window.open(href, '_blank');
      }
    }
  }

  render() {
    return (
        <span className="intlLinked" onClick={this.onClick}>
          { intl.getHTML(this.path) }
        </span>
    );
  }
}

export default IntlLinked;

import React, { Component } from "react";
import AOS from "aos";
import { DgPicture } from "dg-utils";

//Component
import LeftRightMiddle from "../../../blocks/leftRight/leftRightMiddle.js";

//Assets
import background from "../../../../assets/images/contact-hero-1.jpg";
import backgroundMobile from "../../../../assets/images/contact-hero-1-mobile.jpg";
import { initAnimations } from '../../../../assets/files/animations';
import intl from 'react-intl-universal';

class ContactHeader extends Component {
    componentDidMount() {
        AOS.init(initAnimations);
    }

    render() {
	    const headerContent = (
		    <div className="heading wrapper">
			    <h1>{ intl.getHTML('page.Contact.Header.h') }</h1>
			    <p style={{ maxWidth: "400px" }}>
				    { intl.getHTML('page.Contact.Header.p') }
			    </p>
		    </div>
	    );

	    return (
            <LeftRightMiddle
                customLeftRight={"LeftRightMiddle-container header"}
                left={headerContent}
                middle={<div />}
                right={<div />}
                bgImage={
                    <DgPicture className="image-container">
                        <img src={background} alt="" loading="lazy" />
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container">
                        <img src={backgroundMobile} alt="" loading="lazy" />
                    </DgPicture>
                }
                hideOverlay={true}
                config={`right-background`}
            />
        );
    }
}

export default ContactHeader;

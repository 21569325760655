import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions

// Style
import '../../../styles/components/blocks/leftRight.scss';

// Assets

// Components

class LeftRight extends Component {

    render() {

        return (
            <div className={'LeftRight-container'}>
                <div 
                    className={`LeftRight ${this.props.config ? this.props.config : ''}`}
                    style={ this.props.style ? this.props.style : {} }
                >
                    {
                        this.props.left ? (
                            <div className="left-container">
                                <div className="item" data-aos="fade-right">
                                    {this.props.left}
                                </div>
                            </div>
                        ) : ''
                    }
                    {
                        this.props.right ? (
                            <div className="right-container">
                                <div className="item" data-aos="fade-left">
                                    {this.props.right}
                                </div>
                            </div>
                        ) : ''
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { 

})(LeftRight);

import {
    SET_IS_MOBILE_MENU_OPEN,
    SET_IS_MOBILE_SITE,
    SET_JOIN_BUTTON,
} from "./types";

export const setShowJoinButton = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_JOIN_BUTTON,
            payload: data,
        });

        resolve(data);
    });
};

export const setIsMobileMenuOpen = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_IS_MOBILE_MENU_OPEN,
            payload: data,
        });

        resolve(data);
    });
};

export const setIsMobileSite = (isMobileSite) => (dispatch) => {
    dispatch({
        type: SET_IS_MOBILE_SITE,
        payload: isMobileSite,
    });
};

export function navigateTo() {
    window.scrollTo(0, 0);
}

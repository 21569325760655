import React, { Component } from "react";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';

// Assets
import intro from "../../../../assets/images/plan-guardians-phone1.png";
import planBg from "../../../../assets/images/plan-guardians-1.jpg";
import planBgMobile from "../../../../assets/images/plan-guardians-1.jpg";

//Component
import LeftRightMiddle from "../../../blocks/leftRight/leftRightMiddle.js";

class PlanGuardians extends Component {
    render() {
        return (
            <LeftRightMiddle
                hideOverlay={true}
                left={
                    <div className="wrapper right-align">
                        <h2>{ intl.getHTML('page.Plan.Guardians.h') }</h2>
                        <p style={{ maxWidth: "400px" }}>
	                        { intl.getHTML('page.Plan.Guardians.p') }
                        </p>
                    </div>
                }
                middle={
                    <div className="wrapper">
                        <DgPicture className="phone-image">
                            <img
                                src={intro}
                                alt=""
                                loading="lazy"
                            />
                        </DgPicture>
                    </div>
                }
                right={<div></div>}
                bgImage={
                    <DgPicture className="image-container">
                        <img
                            src={planBg}
                            alt=""
                            loading="lazy"
                        />
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container">
                        <img
                            src={planBgMobile}
                            alt=""
                            loading="lazy"
                        />
                    </DgPicture>
                }
                config={`right-background`}
            />
        );
    }
}

export default PlanGuardians;

import React, { Component } from "react";
import { DgPicture } from "dg-utils";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { navigateTo } from "../../../../actions/utility.action";

// Assets
import spendBg from "../../../../assets/images/spend-payasyougo-1.jpg";
import spendBgMobile from "../../../../assets/images/spend-payasyougo-1.jpg";

//Component
import LeftRightMiddle from "../../../blocks/leftRight/leftRightMiddle";
import intl from 'react-intl-universal';

class HomePayBoost extends Component {
    render() {
        return (
            <LeftRightMiddle
                hideOverlay={true}
                right={<div />}
                middle={<div />}
                left={
                    <div className="wrapper right-align">
                        <h2>{ intl.getHTML('page.Spend.Pay.h') }</h2>
                        <p>
	                        { intl.getHTML('page.Spend.Pay.p') }
                        </p>
                        <Link to="/download">
                            <button
                                onClick={this.props.navigateTo}
                                className="btn-primary"
                            >
	                            { intl.getHTML('page.Spend.Pay.cta') }
                            </button>
                        </Link>
                    </div>
                }
                bgImage={
                    <DgPicture className="image-container">
                        <img
                            src={spendBg}
                            alt=""
                            loading="lazy"
                        />
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container">
                        <img
                            src={spendBgMobile}
                            alt=""
                            loading="lazy"
                        />
                    </DgPicture>
                }
                config={`right-background`}
            />
        );
    }
}

export default connect(null, {
    navigateTo,
})(HomePayBoost);

import React, { Component } from "react";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';

// Assets
import irishBg from "../../../../assets/images/aboutus-irishowned-1.jpg";
import irishBgMobile from "../../../../assets/images/aboutus-irishowned-1-mobile.jpg";

//Component
import LeftRightMiddle from "../../leftRight/leftRightMiddle";

class AboutIrish extends Component {
    render() {
        return (
            <LeftRightMiddle
                hideOverlay={true}
                right={<div />}
                middle={<div />}
                left={
                  <div className="wrapper right-align">
                    <p>
                      {intl.getHTML('page.About.Irish.p1')}
                    </p>
                    <p>
                      {intl.getHTML('page.About.Irish.p2')}
                    </p>
                  </div>
                }
                bgImage={
                  <DgPicture className="image-container">
                    <img src={irishBg} alt="" loading="lazy"/>
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container">
                        <img src={irishBgMobile} alt="" loading="lazy"/>
                    </DgPicture>
                }
                config={`right-background`}
            />
        );
    }
}

export default AboutIrish;

import { ContentfulClient, ContentfulClientInterface } from 'react-contentful';
import intl from 'react-intl-universal';

let instance;

class ContentfulService {

  client: ContentfulClientInterface;

  constructor() {
    if (instance) {
      throw new Error("New instance cannot be created!!");
    }
    instance = this;

    this.client = new ContentfulClient({
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    });
  }

  async getInfoPage(key: string, market: string, lang: string) {
    market = market ?? 'ie';
    lang = lang ?? intl.getInitOptions().currentLocale;

    const re = await this.client.getEntries({
      content_type: 'infoPage',
      locale: lang,
      'fields.key': key,
      'metadata.tags.sys.id[in]': 'market' + market.charAt(0).toUpperCase() + market.substring(1),
    })

    return re.items[0];
  }

}

let contentfulServiceInstance = Object.freeze(new ContentfulService());

export default contentfulServiceInstance;

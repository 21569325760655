import React, { Component } from "react";
import { Swipeable } from "react-swipeable";
import { connect } from "react-redux";

// Components
import LandingContent from "./content";
import Footer from "../footer";

class LandingComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scrollPosition: 0,
            slide: 1,
            phoneSlide: 1,
            scrollLock: true,
            isTouchPad: true,
            eventCount: -1,
            eventCountStart: 0,
            width: 0,
        };

        this.mouseHandle = this.mouseHandle.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    mouseHandle(evt) {
        if (
            this.state.eventCount === -1 ||
            new Date().getTime() - this.state.eventCountStart > 300
        ) {
            this.setState({
                eventCountStart: new Date().getTime(),
                eventCount: 0,
            });
        } else {
            this.setState({
                eventCount: this.state.eventCount + 1,
            });

            if (new Date().getTime() - this.state.eventCountStart > 100) {
                console.log("eventCount", this.state.eventCount);
                if (this.state.eventCount > 1) {
                    this.setState({
                        isTouchPad: true,
                        eventCount: -1,
                    });
                } else {
                    this.setState({
                        isTouchPad: false,
                        eventCount: -1,
                    });
                }
            }
        }

        /* 
        // Solution 3
        var isTrackpad = false;
        if (evt.wheelDeltaY) {
            if (evt.wheelDeltaY === evt.deltaY * -3) {
                isTrackpad = true;
            }
        } else if (evt.deltaMode === 0) {
            isTrackpad = true;
        }
        this.setState({
            ...this.state,
            isTouchPad: isTrackpad,
        });
        */
    }

    handleResize(e) {
        this.setState({
            width: e.currentTarget.innerWidth,
        });
    }

    componentDidMount() {
        this.setState({
            width: window.innerWidth,
        });

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        console.log("scrolled");

        let body = document.querySelector("body");
        body.style.overflow = "hidden";

        window.addEventListener("mousewheel", this.handleScroll, false);
        window.addEventListener("DOMMouseScroll", this.handleScroll, false);
        window.addEventListener("resize", this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("mousewheel", this.handleScroll);
        window.removeEventListener("DOMMouseScroll", this.handleScroll);
    }

    handleScroll(e) {
        this.mouseHandle(e);

        let body = document.querySelector("body");

        if (
            this.state.slide === 6 &&
            this.state.scrollPosition < 0 &&
            !this.state.scrollLock
        ) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            this.setState({
                scrollLock: true,
            });

            body.style.overflow = "hidden";
            return;
        } else if (
            this.state.slide >= 5 &&
            this.state.scrollPosition > 0 &&
            this.props.isMobileSite
        ) {
            this.setState({
                scrollLock: false,
            });
            body.style.overflow = "scroll";
        } else if (this.state.slide >= 6 && this.state.scrollPosition > 0) {
            this.setState({
                scrollLock: false,
            });
            body.style.overflow = "scroll";
        } else {
            this.setState({ scrollLock: true });
            body.style.overflow = "hidden";
        }

        if (this.state.isTouchPad) {
            console.log("Is TouchPad");
        } else {
            console.log("Is mouse-wheel");
        }

        console.log("e.deltaY", e.deltaY);

        let scrollAmount = this.state.isTouchPad ? 20 : 1;

        if (e.deltaY >= 10) {
            this.setState({
                scrollPosition: Math.max(0, this.state.scrollPosition) + 1,
            });
        } else if (e.deltaY <= -10) {
            this.setState({
                scrollPosition: Math.min(0, this.state.scrollPosition) - 1,
            });
        }
        console.log("scrollPosition", this.state.scrollPosition);

        if (
            Math.abs(this.state.scrollPosition) > 0 &&
            (this.state.scrollPosition % scrollAmount === 0 ||
                this.props.isMobileSite)
        ) {
            let slideTo =
                this.state.scrollPosition < 0
                    ? this.state.slide - 1
                    : this.state.slide + 1;

            this.setState({
                ...this.state,
                slide: slideTo < 1 ? 1 : slideTo > 6 ? 6 : slideTo,
            });
        }
    }

    render() {
        const config = {
            onSwipedUp: (e) => this.handleScroll(e),
            onSwipedDown: (e) => this.handleScroll(e),
            preventDefaultTouchmoveEvent: this.state.scrollLock,
            trackMouse: true,
        };

        return (
            <React.Fragment>
                <Swipeable {...config} className="swipeable">
                    <LandingContent slide={this.state.slide} />
                </Swipeable>

                {this.state.slide === 6 || this.state.width > 1920 ? (
                    <Footer />
                ) : (
                    ""
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isMobileSite: state.utility.isMobileSite,
    };
};

export default connect(mapStateToProps, {})(LandingComponent);

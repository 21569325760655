import React, { Component } from "react";
import { connect } from "react-redux";
import AOS from "aos";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';

//Component
import LeftRightMiddle from "../../../blocks/leftRight/leftRightMiddle.js";

//Assets
import background from "../../../../assets/images/home-hero-image-1-v2.jpg";
import backgroundMobile from "../../../../assets/images/home-hero-phones.png";
import { initAnimations } from "../../../../assets/files/animations";
import apple from "../../../../assets/icons/apple-store.svg";
import playstore from "../../../../assets/icons/play-store.svg";

class HomeHeader extends Component {
    componentDidMount() {
        AOS.init(initAnimations);
    }

    render() {
        const headerContent = (
            <div className="heading wrapper">
                <h1 data-aos="fade-right" data-aos-delay="400">
	                { intl.getHTML('page.Home.Header.h1') }
                </h1>
                <h2 data-aos="fade-right" data-aos-delay="600">
	                { intl.getHTML('page.Home.Header.h2') }
                </h2>
                <h3 data-aos="fade-right" data-aos-delay="800">
	                { intl.getHTML('page.Home.Header.h3') }
                </h3>
                <div
                    data-aos="fade-right"
                    data-aos-delay="1000"
                    className={`app-container`}
                >
                    <div className="app-container">
                        <a
                            href={
                                "https://apps.apple.com/ie/app/money-jar/id1469836079"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="app-link"
                        >
                            <DgPicture>
                                <img
                                    className="app-img"
                                    src={apple}
                                    alt="App Store"
                                    loading="lazy"
                                />
                            </DgPicture>
                        </a>
                        <a
                            href={
                                "https://play.google.com/store/apps/details?id=com.mymoneyjar.mobile&hl=en_IE&gl=IE"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="app-link"
                        >
                            <DgPicture>
                                <img
                                    className="app-img"
                                    src={playstore}
                                    alt="Google Play"
                                    loading="lazy"
                                />
                            </DgPicture>
                        </a>
                    </div>
                </div>
            </div>
        );
        return (
            <React.Fragment>
                <LeftRightMiddle
                    customLeftRight={"LeftRightMiddle-container header"}
                    showPhone={true}
                    left={headerContent}
                    middle={
                        !this.props.isMobileSite ? (
                            <div className="wrapper phone-wrapper">
                                <DgPicture className="image-container">
                                    <img
                                        src={backgroundMobile}
                                        alt=""
                                        loading="lazy"
                                    />
                                </DgPicture>
                            </div>
                        ) : (
                            <div />
                        )
                    }
                    right={<div />}
                    bgImage={
                        <DgPicture className="image-container">
                            <img
                                src={background}
                                alt=""
                                loading="lazy"
                            />
                        </DgPicture>
                    }
                    bgImageMobile={
                        <DgPicture className="image-container contain home">
                            <img
                                src={backgroundMobile}
                                alt=""
                                loading="lazy"
                            />
                        </DgPicture>
                    }
                    hideOverlay={true}
                    config={`right-background`}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    isMobileSite: state.utility.isMobileSite,
});

export default connect(mapStateToProps, {})(HomeHeader);

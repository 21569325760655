import React, { Component } from "react";
import AOS from "aos";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';

//Component
import LeftRightMiddle from "../../../blocks/leftRight/leftRightMiddle.js";

//Assets
import background from "../../../../assets/images/plan-hero-1-alt.jpg";
import backgroundMobile from "../../../../assets/images/plan-hero-1-alt.jpg";
import { initAnimations } from '../../../../assets/files/animations';

class PlanHeader extends Component {
    componentDidMount() {
        AOS.init(initAnimations);
    }
    
    render() {
	    const headerContent = (
		    <div className="heading wrapper">
			    <h1>
				    { intl.getHTML('page.Plan.Header.h') }
			    </h1>
		    </div>
	    );

	    return (
            <LeftRightMiddle
                customLeftRight={"LeftRightMiddle-container header"}
                left={headerContent}
                middle={<div />}
                right={<div />}
                bgImage={
                    <DgPicture className="image-container">
                        <img src={background} alt="" loading="lazy" />
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container">
                        <img src={backgroundMobile} alt="" loading="lazy" />
                    </DgPicture>
                }
                hideOverlay={true}
                config={`right-background`}
            />
        );
    }
}

export default PlanHeader;

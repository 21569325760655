import React, { Component } from "react";
import Navbar from "../components/navbar/navbar";
import NavbarMobile from "../components/navbar/navbarMobile";
import Header from "../components/blocks/contentContainers/about/aboutHeader.component";
import Footer from "../components/footer";
import AboutIrish from "../components/blocks/contentContainers/about/aboutIrish.component";
import AboutReliable from "../components/blocks/contentContainers/about/aboutReliable.component";
import AboutTransparency from "../components/blocks/contentContainers/about/aboutTransparency.component";
import Offices from '../components/offices';

class About extends Component {
    render() {
	    return (
          <div className="container" style={{ justifyContent: "space-evenly" }}>
            <Navbar/>
            <NavbarMobile/>
            <Header/>
            <AboutTransparency/>
            <AboutIrish/>
            <AboutReliable/>
            <Offices/>
            <Footer/>
          </div>
      );
    }
}

export default About;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import utilityReducer from './utility.reducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    form: formReducer,
    utility: utilityReducer,
});

export default createRootReducer;

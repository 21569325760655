import React, { Component } from "react";
import { DgPicture } from "dg-utils";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import intl from 'react-intl-universal';

// Actions
import { navigateTo } from "../actions/utility.action";

// Style
import "../styles/components/footer.scss";

// Assets
import { socials } from "../assets/files/socials";
import logo from "../assets/logos/MJ-icon-grn.svg";
import apple from "../assets/icons/apple-store.svg";
import playstore from "../assets/icons/play-store.svg";
import flagIE from "../assets/images/flags/ie.svg";
import flagES from "../assets/images/flags/es.svg";

class Footer extends Component {
    render() {
        let renderSocials = socials.map((value, index) => (
            <li
                key={`social_link_${value.name}_${index}`}
                className="social-item"
            >
                <a
                    href={value.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                >
                    <DgPicture>
                        <img className="social-img" src={value.icon} alt={value.name}/>
                    </DgPicture>
                    <DgPicture>
                        <img src={value.iconAlt} className="social-img-alt" alt={value.name} loading="lazy"/>
                    </DgPicture>
                </a>
            </li>
        ));

        return (
            <footer className="footer-container">
                <div className="footer">
                    <div className="logo-container">
                        <img className="logo" src={logo} alt=""/>
                    </div>
                    <div className="app-info-container">
                        <div className="title">
                            {intl.get('component.Footer.download')}
                        </div>
                        <a
                            href={'https://apps.apple.com/ie/app/money-jar/id1469836079'}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="app-link"
                        >
                            <DgPicture>
                                <img className="app-img" src={apple} alt="App Store" loading="lazy"/>
                            </DgPicture>
                        </a>
                        <a
                            href={'https://play.google.com/store/apps/details?id=com.mymoneyjar.mobile&hl=en_IE&gl=IE'}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="app-link"
                        >
                            <DgPicture>
                                <img className="app-img" src={playstore} alt="Google Play" loading="lazy"/>
                            </DgPicture>
                        </a>
                    </div>

                    <div className="socials">
                        <ul className="social-list">{renderSocials}</ul>
                    </div>

                    <ul className="quicklinks">
                        {/*<li>*/}
                        {/*    <Link*/}
                        {/*        to={`/apple-pay`}*/}
                        {/*        className="btn-link"*/}
                        {/*        onClick={() => navigateTo()}*/}
                        {/*    >*/}
                        {/*        {intl.get('component.Footer.applePay')}*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        <li>
                            <Link
                                to={'/contact'}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.support')}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={'/contact'}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.contact')}
                            </Link>
                        </li>
                    </ul>

                    <section className="legal">
                        <h2>IPS Customers</h2>
                        <p>Contact Money Jar customer support if you are not sure who to contact in the event of any complaints.</p>

                        <div className="terms">
                            <div>
                                <span>{intl.get('marketNative.ie')}</span>
                                <img src={flagIE} alt=""/>
                            </div>
                            <Link
                                to={`/terms-and-conditions`}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.terms')}
                            </Link>
                            <strong className="desktop-only">|</strong>
                            <Link
                                to={'/privacy-policy'}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.privacy')}
                            </Link>
                        </div>
                        <div className="legal-note">
                            The MoneyJar Card is issued by IPS Solutions Ltd pursuant to a licence by Mastercard® International Inc.
                            <br/><br/>
                            Funds held within the MoneyJar account are issued by IPS Solutions Ltd. IPS Solutions Ltd is a company incorporated in the Republic of Cyprus under registration number HE346719 with its registered office at Athalassis 62, Office 101, Strovolos 2023, Nicosia, Cyprus. IPS Solutions Ltd is an Electronic Money Institution authorised by the Central Bank of Cyprus to issue electronic money and to provide payment services with licence number 115.1.3.28.
                            <br/><br/>
                            IPS Solutions Ltd is authorised by the Central Bank of Cyprus and regulated by the Central Bank of Ireland for conduct of business rules.
                            <br/><br/>
                            Access to SEPA payments and associated electronic money accounts are provided by IPS Solutions Ltd.
                        </div>

                        <div className="terms">
                            <div>
                                <span>{intl.get('marketNative.es')}</span>
                                <img src={flagES} alt=""/>
                            </div>
                            <Link
                                to={`/terms-and-conditions?lang=es&market=es`}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.termsES')}
                            </Link>
                            <strong className="desktop-only">|</strong>
                            <Link
                                to={'/privacy-policy?lang=es&market=es'}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.privacyES')}
                            </Link>
                        </div>
                        <div className="legal-note">
                            La tarjeta MoneyJar la emite IPS Solutions Ltd de acuerdo con una licencia de Mastercard® International Inc.
                            <br/><br/>
                            Los fondos que se mantienen en la cuenta de MoneyJar los emite IPS Solutions Ltd. IPS Solutions Ltd es una empresa incorporada en la República de Chipre con el número de registro HE346719 y oficina registrada en Athalassis 62, Office 101, Strovolos 2023, Nicosia, Chipre. IPS Solutions Ltd es una entidad de dinero electrónico autorizada por el Banco Central de Chipre para emitir este tipo de moneda digital y ofrecer servicios de pago bajo el número de licencia 115.1.3.28.
                            <br/><br/>
                            IPS Solutions Ltd está autorizada por el Banco Central de Chipre y supervisada por el Banco de España.
                            <br/><br/>
                            El acceso a los pagos SEPA y las cuentas de dinero electrónico asociadas lo proporciona IPS Solutions Ltd.
                            <br/><br/>
                            Copyright © 2024 My Money Jar. Todos los derechos reservados.
                        </div>
                    </section>

                </div>
            </footer>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Footer);

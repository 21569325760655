import React, { Component } from "react";

// Components
import Navbar from "../components/navbar/navbar";
import NavbarMobile from "../components/navbar/navbarMobile";
import Header from "../components/blocks/contentContainers/plan/planHeader.component";
import Footer from "../components/footer";

import PlanMoney from "../components/blocks/contentContainers/plan/planMoney.component";
import PlanGuardians from "../components/blocks/contentContainers/plan/planGuardians.component";
import PlanFree from "../components/blocks/contentContainers/plan/planFree.component";

class Plan extends Component {
    render() {
        return (
            <div className="container" style={{ justifyContent: "space-evenly" }}>
                <Navbar />
                <NavbarMobile />
                <Header />
                <PlanMoney />
                <PlanGuardians />
                <PlanFree />
                <Footer />
            </div>
        );
    }
}

export default Plan;

import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import Navbar from "../components/navbar/navbar";
import NavbarMobile from "../components/navbar/navbarMobile";
import SetupHeader from "../components/blocks/contentContainers/setup/setupHeader.component";
import SetupFast from "../components/blocks/contentContainers/setup/setupFast.component";
import Footer from "../components/footer";

class Setup extends Component {
    render() {
        return (
            <div
                className="container"
                style={{ justifyContent: "space-evenly" }}
            >
                <Navbar />
                <NavbarMobile />
                <SetupHeader />
                <SetupFast />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isMobileSite: state.utility.isMobileSite,
});

export default connect(mapStateToProps, {})(Setup);

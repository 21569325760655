import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';

// Assets
import moneyManagment from "../../../../assets/images/safe.png";

//Component
import LeftRight from "../../../blocks/leftRight/leftRight";

// Actions
import { setIsMobileSite } from "../../../../actions/utility.action";
import { navigateTo } from "../../../../actions/utility.action";
import IntlLinked from '../../../intlLinked';

class HomeSecure extends Component {
    render() {
        return (
            <LeftRight
                right={
                    <div className="wrapper ">
                        <h2>{ intl.getHTML('page.Home.Secure.h1') }</h2>
                        <p className="semi-bold">
	                        { intl.getHTML('page.Home.Secure.p1') }
                        </p>
                        <h2>{ intl.getHTML('page.Home.Secure.h2') }</h2>
                        <p className="semi-bold">
                            <IntlLinked path={'page.Home.Secure.c2'}></IntlLinked>
                        </p>
                        <h2>{ intl.getHTML('page.Home.Secure.h3') }</h2>
                        <p className="semi-bold">
                            <IntlLinked path={'page.Home.Secure.c3'}></IntlLinked>
                        </p>
                        <button className="btn-primary">
                            <NavLink onClick={() => navigateTo()} to="/about">
	                            { intl.getHTML('page.Home.Secure.cta') }
                            </NavLink>
                        </button>
                    </div>
                }
                left={
                    <div className="wrapper image-container">
                        <DgPicture className="phone-img">
                            <img
                                src={moneyManagment}
                                alt=""
                                loading="lazy"
                            />
                        </DgPicture>
                    </div>
                }
                config={`right`}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isMobileSite: state.utility.isMobileSite,
});

export default connect(mapStateToProps, {
    setIsMobileSite,
})(HomeSecure);

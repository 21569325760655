import React, { Component } from "react";
import { connect } from "react-redux";
import { DgPicture } from "dg-utils";
import { NavLink } from "react-router-dom";
import intl from 'react-intl-universal';

// Assets
import intro from "../../../../assets/images/phone_gif-intro.gif";

//Component
import LeftRight from "../../../blocks/leftRight/leftRight";

//Actions
import { navigateTo } from "../../../../actions/utility.action";

class HomeShare extends Component {
    render() {
        return (
            <LeftRight
                left={
                    <div className="wrapper">
                        <DgPicture>
                            <img
                                className="small-img"
                                src={intro}
                                alt=""
                                loading="lazy"
                            />
                        </DgPicture>
                    </div>
                }
                right={
                    <div className="wrapper">
                        <h2>
	                        { intl.get('page.Home.Share.h') }
                        </h2>
                        <p>
	                        { intl.getHTML('page.Home.Share.description') }
                        </p>
                        <button className="btn-primary inverted">
                            <NavLink onClick={() => navigateTo()} to="/plan"> { intl.get('page.Home.Share.cta') }</NavLink>
                        </button>
                    </div>
                }
                config={`green right`}
            />
        );
    }
}

export default connect(null, { navigateTo })(HomeShare);

import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';

// Assets
import setupBg from "../../../../assets/images/home-setup-1.jpg";
import setupBgMobile from "../../../../assets/images/home-setup-1-mobile.jpg";

//Component
import LeftRightMiddle from "../../../blocks/leftRight/leftRightMiddle";

//Actions
import { navigateTo } from "../../../../actions/utility.action";

class HomeSetup extends Component {
    render() {
        return (
            <LeftRightMiddle
                hideOverlay={true}
                left={
                    <div className="wrapper right-align">
                        <h2 className="max-width">
	                        { intl.getHTML('page.Home.Setup.h') }
                        </h2>
                        <p className="max-width">
	                        { intl.get('page.Home.Setup.description') }
                        </p>
                        <button className="btn-primary">
                            <NavLink onClick={() => navigateTo()} to="/setup">
	                            { intl.get('page.Home.Setup.cta') }
                            </NavLink>
                        </button>
                    </div>
                }
                middle={<div></div>}
                right={<div></div>}
                bgImage={
                    <DgPicture className="image-container">
                        <img src={setupBg} alt="" loading="lazy" />
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container">
                        <img src={setupBgMobile} alt="" loading="lazy" />
                    </DgPicture>
                }
                config={`right-background`}
            />
        );
    }
}

export default connect(null, { navigateTo })(HomeSetup);

import React from "react";

export const scripts = [
    {
        name: "Google Tag Manager",
        description: "This is used for tracking stats",
        category: "Analytics",
        isGTM: true,
        code: (
            <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-6Y0W1BMM6E"
            />
        ),
    },
    {
        name: "Google Tag Manager",
        description: "This is used for tracking stats",
        category: "Analytics",
        isGTM: true,
        code: (
            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TGVNVLK');`}</script>
        ),
    },
    {
        name: "Google Analytics",
        description: "This is used for tracking stats",
        category: "Analytics",
        code: (
            <script>
                {`
            window.dataLayer = window.dataLayer || [];
            
            function gtag(){dataLayer.push(arguments);}
            
            gtag('js', new Date());
            
            gtag('config', 'G-6Y0W1BMM6E');
            `}
            </script>
        ),
    },
    {
        name: "Google Analytics",
        description: "This is used for tracking stats",
        category: "Analytics",
        code: (
            <script>
                {`
                window.dataLayer = window.dataLayer || [];

                function gtag(){dataLayer.push(arguments);}

                gtag('js', new Date());

                gtag('config', 'UA-164588736-1');
               `}
            </script>
        ),
    },
    {
        name: "Facebook Pixel",
        description: "This is used for tracking stats",
        category: "Analytics",
        code: (
            <script type="text/javascript">
                {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '967426797380477');
                    fbq('track', 'PageView');
                `}
            </script>
        ),
    },
    {
        name: "LinkedIn Insights",
        description: "LinkedIn Insights Tag",
        category: "Analytics",
        code: (
            <script type="text/javascript">
                {`
                    _linkedin_partner_id = "3348322";
                    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                    window._linkedin_data_partner_ids.push(_linkedin_partner_id);

                    (function(l) {
                        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                            window.lintrk.q=[]}
                        var s = document.getElementsByTagName("script")[0];
                        var b = document.createElement("script");
                        b.type = "text/javascript";b.async = true;
                        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                        s.parentNode.insertBefore(b, s);})(window.lintrk);
                `}
            </script>
        ),
    },
    {
        name: "Marketing",
        description: "This is used for tracking stats",
        category: "Marketing",
        code: <script></script>,
    },
];
